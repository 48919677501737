<!-- 新增模板 -->
<template>
  <div class="addTemplate">
    <back />

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">


      <div class="info">
        <div class="title">基本信息</div>
        <el-row class="dialog_row">
          <el-col :span="22">
            <el-form-item class="item" label="模板名称：" prop="templateName">
              <el-input v-model="ruleForm.templateName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row">
          <el-col :span="20">
            <el-form-item class="item" label="运费设置：" prop="shippingSetting">
              <el-radio v-model="ruleForm.shippingSetting" label="1"
                @input="handleChangeShippingSetting">阶梯计费</el-radio>
              <el-radio v-model="ruleForm.shippingSetting" label="2"
                @input="handleChangeShippingSetting">固定运费</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row" v-if="ruleForm.shippingSetting == 1">
          <el-col :span="20">
            <el-form-item class="item" label="计价方式：" prop="pricingMethod">
              <el-radio v-model="ruleForm.pricingMethod" label="1" @input="clearInput">按件数</el-radio>
              <el-radio v-model="ruleForm.pricingMethod" label="2" @input="clearInput">按重量</el-radio>
              <el-radio v-model="ruleForm.pricingMethod" label="3" @input="clearInput">按容量</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row">
          <el-col :span="20">
            <el-form-item class="item" label="运费计算：" prop="shippingComputed">
              <!-- 阶梯计费-按件数 -->
              <div class="inner" v-if="ruleForm.shippingSetting == 1 && ruleForm.pricingMethod == 1">
                <el-input v-model="ruleForm.inputValue1" placeholder="请输入"></el-input><span>件以内，</span>
                <el-input v-model="ruleForm.inputValue2" placeholder="请输入"></el-input><span>元，</span>
                <span>每增加</span><el-input v-model="ruleForm.inputValue3" placeholder="请输入"></el-input><span>件，</span>
                <span>增加</span><el-input v-model="ruleForm.inputValue4" placeholder="请输入"></el-input><span>元</span>
              </div>
              <!-- 阶梯计费-按重量 -->
              <div class="inner" v-if="ruleForm.shippingSetting == 1 && ruleForm.pricingMethod == 2">
                <el-input v-model="ruleForm.inputValue1" placeholder="请输入"></el-input><span>g以内，</span>
                <el-input v-model="ruleForm.inputValue2" placeholder="请输入"></el-input><span>元，</span>
                <span>每增加</span><el-input v-model="ruleForm.inputValue3" placeholder="请输入"></el-input><span>g，</span>
                <span>增加</span><el-input v-model="ruleForm.inputValue4" placeholder="请输入"></el-input><span>元</span>
              </div>
              <!-- 阶梯计费-按容量 -->
              <div class="inner" v-if="ruleForm.shippingSetting == 1 && ruleForm.pricingMethod == 3">
                <el-input v-model="ruleForm.inputValue1" placeholder="请输入"></el-input><span>ml以内，</span>
                <el-input v-model="ruleForm.inputValue2" placeholder="请输入"></el-input><span>元，</span>
                <span>每增加</span><el-input v-model="ruleForm.inputValue3" placeholder="请输入"></el-input><span>ml，</span>
                <span>增加</span><el-input v-model="ruleForm.inputValue4" placeholder="请输入"></el-input><span>元</span>
              </div>

              <!-- 固定运费-输入 -->
              <div class="inner" v-if="ruleForm.shippingSetting == 2">
                <el-input v-model="ruleForm.inputValue1" placeholder="请输入"></el-input><span>元</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <div class="submit-btn">
      <el-button type="primary" @click="submitForm('ruleForm')">确 认</el-button>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      ruleForm: {
        templateName: '',  // 模板名称
        shippingSetting: '1',  // 运费设置
        pricingMethod: '1',  // 计价方式
        shippingComputed: '',  // 运费计算
        inputValue1: '',  // 运费计算-输入框1
        inputValue2: '',  // 运费计算-输入框2
        inputValue3: '',  // 运费计算-输入框3
        inputValue4: '',  // 运费计算-输入框4
      },
      rules: {
        templateName: [{ required: true, message: '请填写模板名称', trigger: 'blur' }],
        shippingSetting: [{ required: true, message: '请选择运费设置', trigger: 'blur' }],
        pricingMethod: [{ required: true, message: '请选择计价方式', trigger: 'blur' }],
        shippingComputed: [{ required: true, validator: this.validateShippingComputed, trigger: 'blur' }],
      },

    }
  },
  created() {

  },
  methods: {
    validateShippingComputed(rule, value, callback) {
      let isValid = false;

      if (this.ruleForm.shippingSetting == 1) {
        isValid = this.ruleForm.inputValue1 && this.ruleForm.inputValue2 &&
          this.ruleForm.inputValue3 && this.ruleForm.inputValue4;
      } else {
        isValid = this.ruleForm.inputValue1;
      }

      if (isValid) {
        callback();
      } else {
        callback(new Error('请输入运费计算'));
      }
    },
    clearInput() {
      this.ruleForm.inputValue1 = ''
      this.ruleForm.inputValue2 = ''
      this.ruleForm.inputValue3 = ''
      this.ruleForm.inputValue4 = ''
    },
    handleChangeShippingSetting(value) {
      this.clearInput();
      this.ruleForm.shippingSetting = value;
      if (value == 1) {
        this.ruleForm.pricingMethod = '1'
      }
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        let params;
        if (this.ruleForm.shippingSetting == 1) {
          params = {
            shopId: this.$store.state.userInfo.shopId,
            templateName: this.ruleForm.templateName, // 模板名称
            freightType: this.ruleForm.shippingSetting, // 运费设置
            pricingType: this.ruleForm.pricingMethod, // 计件方式
            initialValue: this.ruleForm.inputValue1,
            initialAmount: this.ruleForm.inputValue2,
            addValue: this.ruleForm.inputValue3,
            addAmount: this.ruleForm.inputValue4,

          }
        } else if (this.ruleForm.shippingSetting == 2) {
          params = {
            shopId: this.$store.state.userInfo.shopId,
            templateName: this.ruleForm.templateName, // 模板名称
            freightType: this.ruleForm.shippingSetting, // 运费设置
            initialAmount: this.ruleForm.inputValue1,
          }
        }
        if (valid) {
          this.$axios.post(this.$api.saveFreightTemplate, params).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('新增成功！');
              this.$router.go(-1);
              this.resetForm();
            }
          }).catch(() => {
            this.$message.error('处理失败');
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

  },


}
</script>

<style scoped lang='scss'>
.addTemplate {

  // 基本信息
  .info {
    margin: 30px 20px;
    height: 500px;

    .title {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .dialog_row {

      width: 1050px;

      .item {
        margin-right: 10px;

        .inner {
          .el-input {
            width: 90px;
            margin: 0 5px;
          }

          span {
            color: #272727;
            font-size: 16px;
          }
        }
      }

      /deep/.el-form-item__label {
        color: #272727;
        font-size: 16px;
        padding-right: 30px;
      }

      /deep/ .el-radio__label {
        color: #272727;
        font-size: 16px;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

}
</style>